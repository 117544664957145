import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useAuthStore } from '@/store/auth.store';

export async function authMiddleware(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    const authStore = useAuthStore();
    const isAuthenticated = authStore.isLoggedIn();

    if (!isAuthenticated && to.meta.requiresAuth) {
        authStore.setIntendedRoute(to.fullPath)

        next({name: 'auth.login'})
        return;
    }

    next();
}
