<template>
  <Card class="mb-3" v-if="computedParticipant && selectedOrder">
    <template #title>
      <div class="flex justify-content-between align-items-center">
        {{ computedParticipant.name }}
        <i class="pi pi-arrow-circle-right" v-if="!contentVisible" @click="toggleContent"></i>
        <i class="pi pi-arrow-circle-down" v-if="contentVisible" @click="toggleContent"></i>
      </div>
    </template>
    <template #content v-if="contentVisible">
      <div class="mb-3 text-sm" v-if="computedParticipant.orderLines.length === 0">
        <p>Nog geen bestelling doorgegeven.</p>

        <Divider/>
      </div>

      <div class="mb-3 text-sm" v-if="meIsAdmin">
        Items toegevoegd: {{ computedParticipant.orderLines.length }}<br/>
        Status: {{ computedParticipant.status }}<br/>
      </div>

      <div class="mb-3 text-sm" v-if="isMe && selectedOrder?.status !== 'created'">
        <p>
          Op dit moment kunnen er geen bestelling doorgegeven worden.
        </p>
      </div>

      <div class="mb-3 text-sm"
           v-if="computedParticipant.orderLines.length > 0 && computedParticipant.status != 'cancelled'">
        <h3>Bestelling</h3>
        <p v-if="! showLines">
          Items toegevoegd: {{ computedParticipant.orderLines.length }}<br/>
          Items worden zichtbaar in de volgende status.
        </p>
        <div v-if="showLines">
          <div v-for="line in computedParticipant.orderLines" :key="line.id">
            <table>
              <tr>
                <td style="width: 120px;">Aantal</td>
                <td>{{ line.amount }}</td>
              </tr>
              <tr>
                <td>Omschrijving</td>
                <td>{{ line.description }}</td>
              </tr>
              <tr>
                <td>Stuksprijs</td>
                <td>&euro;{{ line.price }}</td>
              </tr>
              <tr>
                <td>Totaalprijs</td>
                <td>&euro;{{ line.totalPrice }}</td>
              </tr>
              <tr v-if="allowEditLines">
                <td colspan="2">
                  <div class="mt-2 mb-0">
                    <Button
                      severity="info"
                      label="Wijzig"
                      icon="pi pi-pencil"
                      outlined
                      class="mr-2"
                      size="small"
                      @click="editOrderLine(line)"/>

                    <Button
                      severity="danger"
                      label="Verwijder"
                      icon="pi pi-trash"
                      outlined
                      size="small"
                      @click="deleteOrderLine(line)"/>
                  </div>
                </td>
              </tr>
            </table>

            <Divider/>
          </div>

          <template v-if="['picking', 'picked', 'paid'].indexOf(computedParticipant.status) > -1">
            <div class="mt-3 flex justify-content-end">
              <table class="right-0">
                <tr>
                  <td style="padding-right: 20px;">Bedrag bestelling</td>
                  <td class="text-right">&euro;{{ computedParticipant.totalPrice }}</td>
                </tr>
                <tr>
                  <td>Gedeelde kosten</td>
                  <td v-html="otherCostsString" class="text-right"></td>
                </tr>
                <tr>
                  <th>Totaal te betalen</th>
                  <th v-html="totalPriceIncludingOtherCostsString[computedParticipant.id]"
                      class="text-right"></th>
                </tr>
              </table>
            </div>
          </template>
        </div>
      </div>

      <div class="text-center" v-if="allowEditLines">
        <Button
          label="Voeg item toe"
          icon="pi pi-plus"
          outlined
          class="mt-3 mb-0"
          @click="addOrderLine()"/>
      </div>
    </template>
    <template #footer>
      <div class="text-center" v-if="allowCancelledToPicking || allowPickingToPicked || allowPickedBackToPicking || allowToCancelled || allowPickedToPaid || allowPaidBackToPicked">
        <Divider />
      </div>

      <div class="text-center" v-if="allowCancelledToPicking">
        <Button
          label="Meedoen aan de bestelling"
          icon="pi pi-arrow-right"
          icon-pos="right"
          outlined
          class="mt-2 mb-0"
          @click="setStatus('picking')"/>
      </div>

      <div class="text-center" v-if="allowPickingToPicked">
        <Button
          label="Klaar met toevoegen"
          icon="pi pi-arrow-right"
          icon-pos="right"
          class="mt-2 mb-0"
          @click="setStatus('picked')"
        />
      </div>
      <div class="text-center" v-if="allowPickedBackToPicking">
        <Button
          label="Meer toevoegen"
          outlined
          icon="pi pi-arrow-left"
          class="mt-2 mb-0"
          @click="setStatus('picking')"
        />
      </div>

      <div class="text-center" v-if="allowToCancelled">
        <Button
          label="Annuleer inschrijving"
          severity="warning"
          icon="pi pi-ban"
          outlined
          class="mt-2 mb-0"
          @click="setStatus('cancelled', true)"
        />
      </div>

      <div class="text-center" v-if="allowPickedToPaid">
        <Button
          severity="danger"
          outlined
          class="mt-2 mb-0"
          @click="setStatus('paid')"
        >
          <Checkbox v-model="statusIsPaid" binary class="mr-2" />
          Heeft betaald?
        </Button>
      </div>

      <div class="text-center" v-if="allowPaidBackToPicked">
        <Button
          severity="success"
          outlined
          class="mt-2 mb-0"
          @click="setStatus('picked', true)"
        >
          <Checkbox v-model="statusIsPaid" binary class="mr-2" />
          Is betaald!
        </Button>
      </div>
    </template>
  </Card>
</template>

<script setup lang="ts">
import {computed, defineProps, onMounted, ref, watch} from "vue";
import {useConfirm} from "primevue/useconfirm";
import {useAuthStore} from "@/store/auth.store";
import {Order, OrderLine, Participant} from "@/types/api/orders/order.type";
import {useOrderStore} from "@/store/order.store";

const props = defineProps<{
  order: Order,
  participant: Participant,
}>()

const orderStore = useOrderStore();
const authStore = useAuthStore();
const confirm = useConfirm();

const statusIsPaid = computed<boolean>(() => computedParticipant.value?.status === 'paid');

const selectedOrder = computed<Order | null>(() => {
  return orderStore.selectedOrder;
})

const computedParticipant = computed<Participant | null>(() => {
  if (orderStore.selectedOrder === null) {
    return null;
  }

  const filteredParticipants = orderStore.selectedOrder
    .participants
    .filter((participant: Participant) => {
      return participant.id === props.participant.id
    })

  if (filteredParticipants.length === 1) {
    return filteredParticipants[0]
  }

  return null
})

watch(() => orderStore.selectedParticipant?.orderLines, (newLines, oldLines) => {
  if (computedParticipant.value === null || orderStore.selectedParticipant === null) {
    return;
  }

  // only trigger for current participant
  if (orderStore.selectedParticipant.id !== computedParticipant.value.id) {
    return;
  }

  if (newLines && newLines.length > 0 && computedParticipant.value.status === 'joined') {
    setStatus('picking')

    return;
  }
})

const meIsAdmin = computed<boolean>(() =>
  props.order.createdBy === authStore.user?.id
)
const isMe = computed<boolean>(() =>
  props.participant.id === authStore.user?.id
);
const contentVisible = ref(false);
const otherCostsString = computed<string>(() => {
  if (orderStore.otherCostsPerParticipant) {
    return '&euro;' + orderStore.otherCostsPerParticipant;
  }

  return 'nog niet bekend'
})
const totalPriceIncludingOtherCostsString = computed(() => {
  const all: { [key: number]: string } = {};

  (orderStore.selectedOrder?.participants ?? []).forEach((participant: Participant) => {
    if (orderStore.selectedOrder?.otherCosts) {
      all[participant.id] = '&euro;' + Math.round(
        (participant.totalPrice + orderStore.otherCostsPerParticipant) * 100
      ) / 100;
    } else {
      all[participant.id] = 'nog niet bekend';
    }
  })

  return all;
})

const showLines = computed<boolean>(() => {
  if (isMe.value) {
    return true;
  }

  return ['ordering', 'ordered', 'finished'].indexOf(orderStore.selectedOrder?.status ?? '') > -1
})

const allowEditLines = computed<boolean>(() => {
  if (meIsAdmin.value && isMe.value) {
    if ((selectedOrder.value?.status ?? '') === 'created') {
      return ['joined', 'picking'].indexOf(computedParticipant.value?.status ?? '') > -1;
    }

    return ['ordering'].indexOf(selectedOrder.value?.status ?? '') > -1;
  }

  if (isMe.value) {
    return ['created'].indexOf(selectedOrder.value?.status ?? '') > -1
      && ['joined', 'picking'].indexOf(computedParticipant.value?.status ?? '') > -1;
  }

  if (meIsAdmin.value) {
    return orderStore.selectedOrder?.status === 'ordering';
  }

  return false;
})

const allowPickingToPicked = computed<boolean>(() => (isMe.value || meIsAdmin.value)
  && ['created'].indexOf(selectedOrder.value?.status ?? '') > -1
  && computedParticipant.value?.status === 'picking'
)
const allowPickedBackToPicking = computed<boolean>(() => (isMe.value || meIsAdmin.value)
  && ['created'].indexOf(selectedOrder.value?.status ?? '') > -1
  && computedParticipant.value?.status === 'picked'
)
const allowPickedToPaid = computed<boolean>(() => meIsAdmin.value
  && ['ordered', 'finished'].indexOf(selectedOrder.value?.status ?? '') > -1
  && ['picked'].indexOf(computedParticipant.value?.status ?? '') > -1
)
const allowPaidBackToPicked = computed<boolean>(() => meIsAdmin.value
  && ['ordered', 'finished'].indexOf(selectedOrder.value?.status ?? '') > -1
  && ['paid'].indexOf(computedParticipant.value?.status ?? '') > -1
)
const allowToCancelled = computed<boolean>(() => {
  return (isMe.value || meIsAdmin.value)
    && ['ordered', 'finished'].indexOf(selectedOrder.value?.status ?? '') === -1
    && ['joined', 'picking', 'picked'].indexOf(computedParticipant.value?.status ?? '') > -1;
});
const allowCancelledToPicking = computed<boolean>(() => (isMe.value || meIsAdmin.value)
  && computedParticipant.value?.status === 'cancelled'
  && ['created'].indexOf(selectedOrder.value?.status ?? '') > -1
)

const toggleContent = () => contentVisible.value = !contentVisible.value

const previousStatus = ref('')

function setStatus(newStatus: string, requireConfirm = false) {
  if (requireConfirm) {
    confirm.require({
      group: 'headless',
      header: 'Zeker?',
      message: 'Weet je zeker dat je de status wil aanpassen?',
      accept: () => {
        orderStore.setParticipantStatus(props.participant, previousStatus.value, newStatus)
      },
    });

    return;
  }

  orderStore.setParticipantStatus(props.participant, previousStatus.value, newStatus)
}

function addOrderLine() {
  editOrderLine({
    id: null,
    amount: 1,
    description: "",
    price: 0,
    totalPrice: 0,
  })
}

function editOrderLine(line: OrderLine) {
  orderStore.openLineDialog(props.participant, line)
}

const deleteOrderLine = (line: OrderLine) => confirm.require({
  group: 'headless',
  header: 'Zeker?',
  message: 'Weet je zeker dat je dit wil verwijderen?',
  accept: () => {
    orderStore.deleteLine(props.participant, line)
  },
});

onMounted(() => {
  previousStatus.value = props.participant.status;

  if (meIsAdmin.value) {
    toggleContent()
  } else {
    if (isMe.value) {
      toggleContent()
    }
  }
})
</script>
