<template>
  <div class="md:col-6 md:col-offset-3 sm:col-12 sm:col-offset-0">
    <div class="flex justify-content-between">
      <h1>Order Together <i class="pi pi-users"></i></h1>

      <div v-if="authStore.isLoggedIn()">
        <div class="mobile-menu">
          <Button type="button" icon="pi pi-bars" @click="toggleMenu" aria-haspopup="true"
                  aria-controls="overlay_menu"/>
          <Menu ref="menu" id="overlay_menu" :model="items" :popup="true"/>
        </div>

        <nav class="desktop-menu">
          <!--        <router-link :to="{name: 'home'}">Home</router-link> |-->
          <router-link :to="{name: 'orders.list'}">Orders</router-link>
          |
          <router-link :to="{name: 'auth.logout'}">Log out</router-link>
        </nav>
      </div>
    </div>

    <Toast/>

    <ConfirmDialog group="headless">
      <template #container="{ message, acceptCallback, rejectCallback }">
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
          <div
            class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
            <i class="pi pi-question text-5xl"></i>
          </div>
          <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
          <p class="mb-0 text-center" v-html="message.message"></p>
          <div class="flex align-items-center gap-2 mt-4">
            <Button :label="message.acceptLabel || 'save'" @click="acceptCallback"></Button>
            <Button :label="message.rejectLabel || 'cancel'" outlined @click="rejectCallback"></Button>
          </div>
        </div>
      </template>
    </ConfirmDialog>

    <slot name="breadcrumbs"></slot>

    <slot></slot>
  </div>
</template>

<script setup>
import {useAuthStore} from "@/store/auth.store";
import {ref, watch} from "vue";
import {useRouter} from "vue-router";
import {useToastStore} from "@/store/toast.store";
import {useToast} from "primevue/usetoast";

const toast = useToast()
const authStore = useAuthStore();
const toastStore = useToastStore();
const router = useRouter();

watch(() => toastStore.triggerCount, (newCount) =>
  toast.add(toastStore.toasts[toastStore.triggerCount - 1]) // zero based..
);

const menu = ref();
const items = ref([
  {
    label: 'Orders',
    items: [
      {
        label: 'Overview',
        icon: 'pi pi-shopping-bag',
        url: router.resolve({name: 'orders.list'}).href
      },
      {
        label: 'Create',
        icon: 'pi pi-plus',
        url: router.resolve({name: 'orders.create'}).href
      },
    ]
  },
  {
    label: 'Account',
    items: [
      {
        label: 'Log out',
        icon: 'pi pi-sign-out',
        url: router.resolve({name: 'auth.logout'}).href
      },
    ]
  }
])

function toggleMenu(event) {
  menu.value.toggle(event);
}
</script>

<style scoped lang="scss">
.mobile-menu, .desktop-menu {
  &.mobile-menu {
    @media screen and (min-width: 901px) {
      display: none;
    }

    margin: 25px 0 0;
  }

  &.desktop-menu {
    @media screen and (max-width: 900px) {
      display: none;
    }

    padding: 35px 0 0 0;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
}
</style>
