<template>
  <AppLayout>
    <template #breadcrumbs>
      <Breadcrumb :home="breadcrumbsHome" :model="breadcrumbsItems" />
    </template>

    <h1 class="flex justify-content-between">
      Mijn bestellingen
      <Button
          @click="router.push({name: 'orders.create'})"
          icon="pi pi-plus"
          severity="success"
          rounded
          aria-label="Add" />
    </h1>
    <div>
      <Card class="mb-3" v-if="orders.length === 0">
        <template #title>
          Geen bestellingen gevonden..
        </template>
        <template #footer>
          <Button
            label="Start een bestelling"
            icon="pi pi-plus"
            icon-pos="right"
            class="w-full"
            @click="router.push({name: 'orders.create'})" />
        </template>
      </Card>

      <Card class="mb-3" v-for="order in orders" :key="order.uuid">
        <template #title>
          {{ order.name }}
        </template>
        <template #content>
          <ul>
            <li>Datum: {{ order.createdAt }}</li>
            <li>Status: <Badge :value="order.status" :severity="severity(order.status)"></Badge></li>
          </ul>
        </template>
        <template #footer>
          <div class="flex gap-4 mt-1">
            <Button
                label="Open"
                class="w-full"
                @click="router.push({name: 'orders.detail', params: {uuid: order.uuid}})" />
          </div>
        </template>
      </Card>
    </div>
  </AppLayout>
</template>

<script setup lang="ts">
import AppLayout from "@/layouts/AppLayout.vue";
import {useOrderStore} from "@/store/order.store";
import Card from 'primevue/card';
import {computed, ref} from "vue";
import router from "@/router";

const orderStore = useOrderStore();

const breadcrumbsHome = ref({
  icon: 'pi pi-home',
  url: router.resolve({name: 'orders.list'}).href,
});
const breadcrumbsItems = ref([
  { label: 'Mijn bestellingen' },
]);

const orders = computed(() => orderStore.orders)

orderStore.fetch();

function severity(status: string) {
  switch (status) {
    case 'created':
    case 'ordering':
    case 'ordered':
      return 'info';

    case 'finished':
      return 'success';

    case 'cancelled':
      return 'danger';
  }
}

</script>

<style scoped>
ul li {
  margin-bottom: 5px;
}
</style>
