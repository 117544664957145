import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import config from '@/configService';
import HomeView from '@/pages/HomePage.vue'
import LoginView from "@/pages/Auth/LoginPage.vue";
import {authMiddleware} from "@/router/middleware";
import {useAuthStore} from "@/store/auth.store";
import OrderCreateView from "@/pages/Order/OrderCreatePage.vue";
import OrderListView from "@/pages/Order/OrderListPage.vue";
import OrderDetailView from "@/pages/Order/OrderDetailPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    async beforeEnter(to, from, next): Promise<void> {
      return next({name: 'orders.list'});
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/auth',
    children: [
      {
        path: '/login',
        name: 'auth.login',
        component: LoginView,
      },
      {
        path: '/auth/logout',
        name: 'auth.logout',
        async beforeEnter(to, from, next): Promise<void> {
          await useAuthStore().logout();
          return next({name: 'auth.login'});
        },
        // Definieer een dummy component om TypeScript tevreden te stellen
        component: { template: '<router-view />' },
      },
    ]
  },
  {
    path: '/orders',
    children: [
      {
        path: '',
        name: 'orders.list',
        component: OrderListView,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'create',
        name: 'orders.create',
        component: OrderCreateView,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: ':uuid',
        name: 'orders.detail',
        component: OrderDetailView,
        meta: {
          requiresAuth: true,
        }
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(config.baseURL),
  routes,
})

router.beforeEach(authMiddleware);

export default router
