import {axios, AxiosError, handleApiError} from '@/axiosConfig';
import {defineStore} from 'pinia';
import {User} from "@/types/user.type";
import {Login, CheckVerification, CsrfRefresh} from "@/types/api/user/login.type";

interface AuthStoreState {
  loggedIn: boolean;
  user: User | null;
  intendedPath: string | null;
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthStoreState => ({
    loggedIn: false,
    user: null,
    intendedPath: null,
  }),
  actions: {
    async sentVerificationToken(phone: string) {
      try {
        await axios.post(
          '/api/auth/sentVerification',
          {
            phone: phone
          }
        );

        return true;
      } catch (e) {
        handleApiError(e)

        return false;
      }
    },
    async checkVerificationToken(phone: string, token: string, remember: boolean) {
      try {
        const response = await axios.post<CheckVerification>(
          '/api/auth/checkVerification',
          {
            phone: phone,
            token: token.toString(),
            remember: remember ? 'yes' : 'no',
          }
        );

        if (response.data.user) {
          this._handleLogin(response.data)
        }

        return response.data;
      } catch (e) {
        handleApiError(e)

        return null;
      }
    },
    async rememberMe() {
      try {
        await axios.post(
          '/api/auth/rememberMe',
        );
      } catch (e) {
        handleApiError(e)

        return null;
      }
    },
    async register(phone: string, token: number, name: string) {
      try {
        const response = await axios.post<Login>(
          '/api/auth/register',
          {
            phone: phone,
            token: token.toString(),
            name: name,
          }
        );

        this._handleLogin(response.data)

        return response.data;
      } catch (e: AxiosError | unknown) {
        handleApiError(e)

        return null;
      }
    },
    async logout(sendRequest: boolean = true) {
      try {
        if (sendRequest) {
          await axios.post(
            '/api/auth/logout',
          );
        }

        this.loggedIn = false;
        localStorage.removeItem('user_profile');
        localStorage.removeItem('is_logged_in');
      } catch (e: AxiosError | unknown) {
        handleApiError(e)

        return null;
      }
    },
    async refreshCSRF() {
      try {
        const response = await axios.post<CsrfRefresh>(
          '/api/auth/regenerate-csrf',
        );

        return response.data.token;
      } catch (e: AxiosError | unknown) {
        handleApiError(e)

        return null;
      }
    },
    isLoggedIn(): boolean {
      console.log('isLoggedIn?', this.loggedIn)
      return this.loggedIn;
    },
    setIntendedRoute(fullPath: string): void {
      this.intendedPath = fullPath;
    },
    _handleLogin(data: Login | CheckVerification): void {
      console.log('_handleLogin', data)

      if (data.user) {
        this.loggedIn = true;

        // save in local storage, so it can be fetched after closing page
        localStorage.setItem('is_logged_in', JSON.stringify(true));

        this.user = {
          id: data.user.id,
          fullName: data.user.name,
        };

        // save in local storage, so it can be fetched after closing page
        localStorage.setItem('user_profile', JSON.stringify(this.user));

        console.log('Data saved to localStorage')
      }
    },
    async initialize(): Promise<void> {
      const loggedIn = localStorage.getItem('is_logged_in');
      if (loggedIn) {
        this.loggedIn = true;
      }

      const userProfile = localStorage.getItem('user_profile');
      if (userProfile) {
        this.user = JSON.parse(userProfile)
      }

      if (this.loggedIn) {
        try {
          await axios.post(
            '/api/auth/check',
          );

          return;
        } catch (e: AxiosError | unknown) {
          handleApiError(e);

          throw e;
        }
      }
    }
  }
});
