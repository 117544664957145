<template>
  <AppLayout>
    <div class="surface-card p-4 shadow-2 border-round w-full">
      <div class="text-center mb-5">
        <!--                <img src="/images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3" />-->
        <div class="text-900 text-3xl font-medium mb-3">Welkom</div>
        <p class="text-600 font-medium line-height-3 mb-1">
          Log in met je telefoonnummer en sms-code of meld je eenvoudig aan door je telefoonnummer in te voeren.
        </p>
      </div>

      <div>
        <div class="mb-3">
          <label for="phone" class="block text-900 font-medium mb-2">Telefoonnummer</label>
          <InputText
            v-model="phone"
            @keyup.enter="handleLoginFormSubmission"
            id="phone"
            type="tel"
            class="w-full"
            minlength="10"
            maxlength="10"
            :disabled="smsCodeSent"
          />
          <small v-if="smsCodeSent" @click="resetForm">
            <i class="pi pi-replay"></i>Reset formulier
          </small>
        </div>

        <div v-if="smsCodeSent" class="mb-3">
          <label for="token" class="block text-900 font-medium mb-2">Verificatiecode</label>
          <InputNumber
              ref="tokenField"
              v-model="token"
              @keyup.enter="handleLoginFormSubmission"
              id="token"
              class="w-full"
              :use-grouping="false"
              :disabled="smsCodeVerified"
              autocomplete="one-time-code"
          />
        </div>

        <div v-if="registerMode" class="mb-3">
          <label for="name" class="block text-900 font-medium mb-2">Name</label>
          <InputText
              ref="nameField"
              v-model="name"
              @keyup.enter="handleLoginFormSubmission"
              id="name"
              class="w-full"
          />
        </div>

        <div class="mb-3 flex align-items-center">
          <div class="p-checkbox p-component mr-2">
            <Checkbox
              inputId="rememberMe"
              ref="rememberField"
              v-model="remember"
              binary />
          </div>
          <label for="rememberMe" class="w-full">Onthoud mij</label>
        </div>

        <Button
          :label="continueLabel"
          icon="pi pi-user"
          class="w-full"
          @click="handleLoginFormSubmission" />
      </div>
    </div>
  </AppLayout>
</template>

<script setup>
import {computed, nextTick, ref} from 'vue'
import AppLayout from "@/layouts/AppLayout.vue";
import {useAuthStore} from "@/store/auth.store";
import router from "@/router";
import {useConfirm} from "primevue/useconfirm";

const confirm = useConfirm();
const authStore = useAuthStore();

const tokenField = ref();
const nameField = ref();
const rememberField = ref();
const registerMode = ref(false);
const smsCodeSent = ref(false);
const smsCodeVerified = ref(false);
const phone = ref(null);
const token = ref(null);
const name = ref('');
const remember = ref(false);

const continueLabel = computed(() => {
  if (smsCodeSent.value === false) {
    return 'Verstuur SMS code'
  }

  if (registerMode.value === null) {
    return 'Controleer code'
  }

  if (registerMode.value === true) {
    return 'Registreer';
  }

  if (registerMode.value === false) {
    return 'Log in';
  }

  return '??';
})

function resetForm() {
  registerMode.value = false;
  smsCodeSent.value = false;
  smsCodeVerified.value = false;
  phone.value = null;
  token.value = null;
  name.value = '';
}

async function handleLoginFormSubmission() {
  if (token.value === null) {
    const verificationSuccess = await authStore.sentVerificationToken(phone.value)

    if (verificationSuccess) {
      smsCodeSent.value = true;

      nextTick(() => {
        tokenField.value.$el.querySelector('input').focus();
      })
    }

    return;
  }

  if (smsCodeVerified.value === false) {
    const verification = await authStore.checkVerificationToken(phone.value, token.value, remember.value);
    if (verification === null) {
        return;
    }

    smsCodeVerified.value = true;
    registerMode.value = verification.startRegisterFlow

    if (registerMode.value === false) {
      await handleLogin()
    }

    return
  }

  if (registerMode.value) {
    const registerResponse = await authStore.register(phone.value, token.value, name.value);
    if (registerResponse === null) {
        return;
    }

    await handleLogin()
  }
}

async function handleLogin() {
  if (authStore.isLoggedIn()) {
    if (! remember.value) {
      confirm.require({
        group: 'headless',
        header: 'Langer ingelogd blijven?',
        message: 'Om de kosten om deze app te draaien zo laag mogelijk te houden vragen we je om automatisch langer ingelogd te blijven.<br /><br />Vind je dat goed?',
        acceptLabel: 'Ok',
        rejectLabel: 'Nee, liever niet',
        accept: () => {
          authStore.rememberMe()
          redirectAfterLogin();
        },
        reject: () => redirectAfterLogin(),
      });

      return;
    }

    redirectAfterLogin()
  }
}

function redirectAfterLogin() {
  if (authStore.intendedPath?.length > 0) {
    router.push(authStore.intendedPath)
    return;
  }

  router.push({'name': 'home'})
}
</script>
