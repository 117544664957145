<template>
  <AppLayout>
    <h1>Home</h1>
    <p>Hello there..</p>
  </AppLayout>
</template>

<script setup lang="ts">
import AppLayout from "@/layouts/AppLayout.vue";
</script>
