// @ts-nocheck

interface Config {
    baseURL: string;
    apiBaseURL: string;
}

const config: Config = {
    baseURL: import.meta.VITE_BASE_URL || '',
    apiBaseURL: import.meta.VITE_APP_API_BASE_URL || '',
};

export default config;
