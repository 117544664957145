<template>
  <AppLayout>
    <template #breadcrumbs>
      <Breadcrumb :home="breadcrumbsHome" :model="breadcrumbsItems" />
    </template>

    <h1 v-if="!selectedOrder">Loading..</h1>
    <div v-if="selectedOrder">
      <h1>Bestelling gegevens</h1>

      <!--
      The following messages are also viewable in OrderCreatePage
      Which are triggered in OrderController::detail
      -->
      <Message v-if="selectedOrder.status !== 'created'" severity="warn" :closable="false">
        <p v-if="selectedOrder.status === 'ordering'">
          De bestelling wordt nu geplaatst. Je kunt nu niets meer toevoegen of wijzigen.<br />
          Vraag aan {{ selectedOrder.creator.name }} of de bestelling status aangepast kan worden zodat wijzigingen mogelijk zijn.
        </p>

        <p v-if="selectedOrder.status === 'ordered'">
          De bestelling is al geplaatst. Je kunt nu niets meer toevoegen of wijzigen. Wel kun je zelf een nieuwe bestelling starten.
        </p>

        <p v-if="selectedOrder.status === 'finished'">
          Deze bestelling is afgerond en afgesloten. Je kunt zelf een nieuwe bestelling starten.
        </p>

        <p v-if="selectedOrder.status === 'cancelled'">
          Deze bestelling is geannuleerd. Je kunt zelf een nieuwe bestelling starten.
        </p>

        <Button
          @click="router.push({name: 'orders.create'})"
          icon="pi pi-plus"
          icon-pos="right"
          label="Start nieuwe bestelling"
          class="mb-2"
        />
      </Message>

      <Card class="mb-3">
        <template #title>{{ selectedOrder.name }}</template>
        <template #content>
          <p class="mb-2">
            Aantal deelnemers: {{ selectedOrder.participants.length }}<br />
            Open voor bestellingen: {{ openForOrdersString(selectedOrder.status) }}
          </p>

          <Button
              icon="pi pi-external-link"
              icon-pos="right"
              class="w-full mt-2"
              label="Bekijk menu"
              @click="openMenuLink()"
          />

          <Button
            v-if="selectedOrder.status === 'created'"
            outlined
            icon="pi pi-share-alt"
            icon-pos="right"
            class="w-full mt-2"
            :label="canShare ? 'Deel deze order' : 'Kopieer uitnodigingslink'"
            @click="canShare ? shareUrl() : copyUrl()"
          />
          <Message v-if="copied || shared" class="mb-0" severity="success" :closable="false">
            <template v-if="copied">Succesvol gekopieerd!</template>
            <template v-if="shared">Succesvol gedeeld!</template>
          </Message>
        </template>
      </Card>

      <OrderStatus />

      <h1>Deelnemers ({{selectedOrder.participants.length}})</h1>
      <OrderParticipant
          v-for="participant in selectedOrder.participants"
          :key="participant.id"
          :order="selectedOrder"
          :participant="participant"/>

      <LineDialog />
    </div>
  </AppLayout>
</template>

<script setup lang="ts">
import AppLayout from "@/layouts/AppLayout.vue";
import {useOrderStore} from "@/store/order.store";
import {computed, onMounted, ref} from "vue";
import router from "@/router";
import OrderParticipant from "@/components/Order/OrderParticipant.vue";
import LineDialog from "@/components/Order/LineDialog.vue";
import OrderStatus from "@/components/Order/OrderStatus.vue";
import {useClipboard, useShare} from "@vueuse/core";
import {Order} from "@/types/api/orders/order.type";

const orderStore = useOrderStore();
const { copy } = useClipboard();
const { share, isSupported: canShare } = useShare();

const selectedOrder = computed<Order|null>(() => orderStore.selectedOrder)

const openForOrdersString = (status: string): string => (status === 'created') ? 'ja' : 'nee'
const copied = ref<boolean>(false);
const shared = ref<boolean>(false);

const breadcrumbsHome = ref({
  icon: 'pi pi-home',
  url: router.resolve({name: 'orders.list'}).href,
});
const breadcrumbsItems = computed(() => [
  { label: 'Mijn bestellingen', url: router.resolve({name: 'orders.list'}).href, },
  { label: 'Details' },
]);

const urlToShare = computed(() => window.location.origin + router.resolve({
  name: 'orders.detail',
  params: {uuid: orderStore.selectedOrder?.uuid}
}).href);

function openMenuLink() {
  window.open(selectedOrder.value?.menuLink, '_blank')
}

async function shareUrl() {
  if (canShare.value) {
    try {
      await share({
        title: 'Bestel je mee? ' + selectedOrder.value?.name,
        text: 'Via deze website kun je gemakkelijk registeren met je mobiele telefoon en doorgeven wat je wil.',
        url: urlToShare.value,
      });
      shared.value = true;

      setTimeout(() => {
        shared.value = false;
      }, 2000);
    } catch (error) {
      alert('Fout bij het delen. Je kunt ook de browser URL delen.')
    }
  }
}

async function copyUrl() {
  try {
    await copy(urlToShare.value)
    copied.value = true;

    setTimeout(() => {
      copied.value = false;
    }, 2000);
  } catch (error) {
    alert('Fout bij het kopieren. Je kunt ook de browser URL delen.')
  }
}

onMounted(() => {
  orderStore.fetchByUuid(
    router.currentRoute.value.params['uuid'] as string
  )
})
</script>
