import {defineStore} from 'pinia';
import {CreateOrder} from "@/types/api/orders/create.type";
import {axios, AxiosError, handleApiError, isAxiosError} from '@/axiosConfig';
import {Order, OrderLine, Participant} from "@/types/api/orders/order.type";
import {useToastStore} from "@/store/toast.store";
import router from "@/router";

interface OrderStoreState {
  orders: Array<Order>,
  selectedOrder: Order | null,
  selectedParticipant: Participant | null
  selectedLine: OrderLine | null,
  lineDialogVisible: boolean,
}

export const useOrderStore = defineStore('order', {
  state: (): OrderStoreState => ({
    orders: [],
    selectedOrder: null,
    selectedParticipant: null,
    selectedLine: null,
    lineDialogVisible: false,
  }),
  actions: {
    async create(createOrder: CreateOrder): Promise<Order | null> {
      try {
        const response = await axios.post<Order>(
          '/api/orders',
          createOrder
        );

        // save in Pinia store
        this.orders.push(response.data)

        return response.data;
      } catch (e: AxiosError | unknown) {
        handleApiError(e)

        return null;
      }
    },
    async initialize(): Promise<void> {
      try {
        const response = await axios.get<Order[]>('/api/orders')
        this.orders = response.data
      } catch (e: AxiosError | unknown) {
        handleApiError(e)
      }
    },
    async fetch(): Promise<Order[] | null> {
      try {
        const response = await axios.get<Order[]>('/api/orders');

        // save in Pinia store
        this.orders = response.data

        return response.data;
      } catch (e: AxiosError | unknown) {
        handleApiError(e)

        return null;
      }
    },
    async fetchByUuid(uuid: string) {
      try {
        const response = await axios.get<Order>('/api/orders/' + uuid);

        this.selectedOrder = response.data

        return response.data;
      } catch (e: AxiosError | unknown) {
        if (isAxiosError(e) && e.response) {
          const axiosError = e as AxiosError<{ message: string, status: string }>;
          const errorResponse = axiosError.response?.data;

          if (axiosError.response?.status === 400 && errorResponse?.message === 'invalid_order_status') {
            router.push({
              name: 'orders.create',
              query: {
                unableToJoinStatus: errorResponse.status,
              }
            })

            return null;
          }

          if (axiosError.response?.status === 404) {
            router.push({
              name: 'orders.list'
            }).then(() => {
              // let the toast trigger..
              handleApiError(e)
            })

            return null;
          }
        }

        handleApiError(e)

        return null;
      }

      this.selectedOrder = this.orders
        .filter((i) => i.uuid === uuid)[0]
    },
    async setStatus(previousStatus: string, newStatus: string, additionalData: object) {
      if (!this.selectedOrder) {
        throw new Error('No order selected..')
      }

      try {
        const response = await axios.post<Order>(
          '/api/orders/' + this.selectedOrder.uuid + '/change-status/' + newStatus,
          additionalData,
        );

        this.selectedOrder = response.data;
      } catch (e: AxiosError | unknown) {
        handleApiError(e)

        this.selectedOrder.status = previousStatus;
      }

      return this.selectedOrder.status;
    },
    setPaymentLink(link: string) {
      if (!this.selectedOrder) {
        throw new Error('No order selected..')
      }

      this.selectedOrder.paymentLink = link;
    },
    async setParticipantStatus(participant: Participant, previousStatus: string, newStatus: string) {
      if (!this.selectedOrder) {
        throw new Error('No order selected..')
      }

      const participantIndex = this.selectedOrder
        .participants
        .findIndex((i) => i.id === participant.id)

      if (participantIndex === -1) {
        throw new Error('Unknown participant..')
      }

      try {
        await axios.post('/api/orders/' + this.selectedOrder.uuid + '/' + participant.id + '/change-status/' + newStatus);
      } catch (e: AxiosError | unknown) {
        handleApiError(e)

        // todo: implement reset of select input..
      }

      const foundParticipant = this.selectedOrder.participants[participantIndex]
      foundParticipant.status = newStatus

      this.selectedOrder.participants = [
        ...this.selectedOrder.participants.slice(0, participantIndex),
        foundParticipant,
        ...this.selectedOrder.participants.slice(participantIndex + 1)
      ]
    },
    openLineDialog(participant: Participant, orderLine: OrderLine) {
      this.selectedParticipant = participant;
      this.selectedLine = orderLine;

      this.lineDialogVisible = true;
    },
    closeLineDialog() {
      this.lineDialogVisible = false;
      this.selectedLine = null;
    },
    async upsertLine(upsertingLine: OrderLine) {
      if (this.selectedOrder === null) {
        throw new Error('Not an order selected for update..')
      }

      if (this.selectedParticipant === null) {
        throw new Error('Not a participant selected for update..')
      }

      try {
        const response = await axios.post<Participant>(
          '/api/orders/' + this.selectedOrder.uuid + '/' + this.selectedParticipant.id + '/upsert-line',
          upsertingLine
        );

        this.selectedLine = upsertingLine;
        this.selectedParticipant = response.data;

        this.selectedOrder = {
          ...this.selectedOrder,
          participants: this.selectedOrder.participants.map((participant: Participant) => {
            if (this.selectedParticipant && participant.id === this.selectedParticipant.id) {
              return this.selectedParticipant;
            }

            return participant;
          })
        }

        return response.data;
      } catch (e: AxiosError | unknown) {
        handleApiError(e)

        return null;
      }
    },
    async deleteLine(participant: Participant, line: OrderLine) {
      // todo: merge code with upsertLine to not have duplicate code

      if (this.selectedOrder === null) {
        throw new Error('Not an order selected for update..')
      }

      try {
        const response = await axios.delete<Participant>(
          '/api/orders/' + this.selectedOrder.uuid + '/' + participant.id + '/delete-line/' + line.id,
        );

        this.selectedParticipant = response.data;

        this.selectedOrder = {
          ...this.selectedOrder,
          participants: this.selectedOrder.participants.map((participant: Participant) => {
            if (this.selectedParticipant && participant.id === this.selectedParticipant.id) {
              return this.selectedParticipant;
            }

            return participant;
          })
        }

        return response.data;
      } catch (e: AxiosError | unknown) {
        handleApiError(e)

        return null;
      }
    },
    async sendPaymentLink() {
      if (this.selectedOrder === null) {
        throw new Error('Not an order selected for update..')
      }

      try {
        await axios.post(
          '/api/orders/' + this.selectedOrder.uuid + '/send-payment-link'
        );

        const toastStore = useToastStore();
        toastStore.showToast({
          severity: 'success',
          summary: 'Betaallink verzonden.',
          life: 3500,
        })
      } catch (e: AxiosError | unknown) {
        handleApiError(e)
      }
    },
  },
  getters: {
    otherCostsPerParticipant: (state: OrderStoreState) => {
      if (!state.selectedOrder?.otherCosts) {
        return 0;
      }

      const countParticipatingParticipants = state.selectedOrder
        .participants
        .filter((participant: Participant) => ['picked', 'paid'].indexOf(participant.status) > -1)
        .length ?? 0;

      if (countParticipatingParticipants === 0) {
        return 0;
      }

      return Math.round(
        (state.selectedOrder.otherCosts / countParticipatingParticipants) * 100
      ) / 100;
    },
  }
});
