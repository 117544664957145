import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import config from './configService';
import {createPinia} from "pinia";
import {useAuthStore} from "@/store/auth.store";
import {useOrderStore} from "@/store/order.store";

import './styles/style.scss'

const pinia = createPinia()

const app = createApp(App)
    .use(router)
    .use(pinia)
    .use(PrimeVue)
    .use(ToastService)
    app.use(ConfirmationService)

// Apply config to app
app.provide('config', config);

const authStore = useAuthStore();
authStore.initialize()
  .then(() => {
      if (authStore.isLoggedIn()) {
          useOrderStore().initialize();
      }
  })

app.mount('#app')
