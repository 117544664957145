<template>
  <AppLayout>
    <template #breadcrumbs>
      <Breadcrumb :home="breadcrumbsHome" :model="breadcrumbsItems" />
    </template>

    <h1>Start bestelling</h1>

    <Card>
      <template #content>

        <Message v-if="unableToJoinStatus" severity="error" :closable="false">
          <p>Het is op dit moment niet mogelijk om jezelf toe te voegen aan de bestelling.</p>
          <p v-if="unableToJoinStatus === 'ordering'">Op dit moment wordt de bestelling geplaatst. De persoon die de bestelling is gestart kan deze mogelijk nog openzetten voor nieuwe deelnemers.</p>
          <p v-if="unableToJoinStatus === 'ordered'">De bestelling is reeds geplaatst.</p>
          <p v-if="unableToJoinStatus === 'finished'">De bestelling is afgerond.</p>
          <p v-if="unableToJoinStatus === 'cancelled'">De bestelling is geannuleerd.</p>
          <p>Je kunt via onderstaand formulier gemakkelijk een nieuwe bestelling starten.</p>
        </Message>

        <div>
          <label for="email" class="block text-900 font-medium mb-2">Naam</label>
          <InputText
              v-model="form.name"
              placeholder="Naam van je bestelling of het restaurant"
              id="email"
              type="text"
              class="w-full mb-3"
          />

          <label for="menuLink" class="block text-900 font-medium mb-2">Menu link</label>
          <InputText
              v-model="form.menuLink"
              placeholder="https://www.thuisbezorgd.nl/menu/new-york-pizza-geldermalsen"
              id="menuLink"
              type="url"
              class="w-full mb-3"
          />

          <div class="mb-3">
            <Message class="mt-0" v-if="isMobile" severity="info" :closable="false">
              <p>
                In de Thuisbezorgd app kun je de link niet kopiëren. Je kunt via de browser van je telefoon de link wél gemakkelijk kopiëren.
              </p>
            </Message>
            <Button
              v-if="!isMobile"
              severity="info"
              label="Zoek op Thuisbezorgd.nl"
              icon="pi pi-external-link"
              icon-pos="right"
              @click="openThuisbezorgd()" />
          </div>

          <Button label="Start" icon="pi pi-shopping-bag" class="w-full" @click="creatOrder"></Button>
        </div>
      </template>
    </Card>
  </AppLayout>
</template>

<script setup lang="ts">
import AppLayout from "@/layouts/AppLayout.vue";
import {computed, ref} from "vue";
import {useOrderStore} from "@/store/order.store";
import router from "@/router";
import {Order} from "@/types/api/orders/order.type";
import {useRoute} from "vue-router";
import {useConfirm} from "primevue/useconfirm";

const route = useRoute();
const confirm = useConfirm();
const orderStore = useOrderStore();

const breadcrumbsHome = ref({
  icon: 'pi pi-home',
  url: router.resolve({name: 'orders.list'}).href,
});
const breadcrumbsItems = ref([
  { label: 'Mijn bestellingen', url: router.resolve({name: 'orders.list'}).href, },
  { label: 'Start bestelling' },
]);

const unableToJoinStatus = route.query?.unableToJoinStatus

const isMobile = computed(() =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
);

const openThuisbezorgd = () => window.open('/redirect-thuisbezorgd', '_blank')

const form = ref({
  name: '',
  menuLink: '',
})

const creatOrder = () => {
  orderStore.create({
    name: form.value.name,
    menu_link: form.value.menuLink,
  })
      .then((order: Order|null) => {
        if (order !== null) {
          router.push({
            name: 'orders.detail',
            params: {uuid: order.uuid}
          })
        }
      })
}
</script>
