<template>
  <Card v-if="selectedOrder && currentUserIsCreator" class="mb-3">
    <template #title>
      Order status ({{ selectedOrder.status }})
    </template>
    <template #content>
      <p>Omdat jij de beheerder bent kun je de status van de order en andere gebruikers aanpassen.</p>

      <div v-if="isCreated">
        <p>
          Wanneer iedereen een bestelling heeft ingediend kun je hier de inschrijvingen stoppen en de bestelling bij de
          leverancier starten.
        </p>
      </div>

      <div v-if="isOrdering">
        <p>
          Plaats nu de bestelling bij de leverancier.
        </p>

        <Divider/>

        <div class="flex flex-column gap-3">
          <div>
            <div class="flex flex-column gap-2">
              <label for="otherCosts">Overige kosten</label>
              <InputNumber
                v-model="selectedOrder.otherCosts"
                id="otherCosts"
                class="flex-auto"
                mode="currency"
                currency="EUR"
                locale="nl-NL"/>

              <small id="otherCosts-help">
                Vul hier bezorgkosten of andere kosten in die over alle deelnemers verdeeld moeten worden.
              </small>
            </div>
          </div>

          <div>
            <div class="flex flex-column gap-2">
              <label for="otherCosts">Betaallink</label>
              <InputText
                v-model="selectedOrder.paymentLink"
                @paste="extractLink"
                placeholder="https://tikkie.me/xyz"
                id="paymentLink"
                class="flex-auto"
                mode="url"/>

              <small id="paymentLink-help">
                Vul hier een betaallink in waar gebruikers zelf het juiste bedrag in kunnen overboeken.
              </small>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isOrdered">
        <p>
          Je kunt nu per deelnemer de status aanpassen naar betaald.<br/>
          Wanneer je hebt doorgegeven dat iedereen heeft betaald sluiten we deze order automatisch.
        </p>

        <Button
          icon="pi pi-send"
          iconPos="right"
          label="Stuur betaallink via sms"
          @click="sendPaymentLink"
        />
      </div>
    </template>
    <template #footer>
      <div v-if="isCreated">
        <Button
          icon="pi pi-arrow-right"
          iconPos="right"
          label="Stop inschrijvingen & plaats bestelling bij leverancier"
          style="width: 100%;"
          @click="setStatus('ordering')"/>
      </div>

      <div v-if="isOrdering" class="flex flex-column justify-content-around gap-2">
        <Button
          icon="pi pi-arrow-left"
          outlined
          label="Vorige status"
          @click="setStatus('created')"/>

        <Button
          icon="pi pi-arrow-right"
          iconPos="right"
          label="Bestelling geplaatst bij leverancier"
          @click="setStatus(
          'ordered',
          {
            'otherCosts': selectedOrder.otherCosts,
            'paymentLink': selectedOrder.paymentLink,
          },
          {
            header: 'Open betaallink?',
            message: confirmToOrderedHTML
          })"></Button>
      </div>
    </template>
  </Card>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import {useConfirm} from "primevue/useconfirm";
import {useOrderStore} from "@/store/order.store";
import {useAuthStore} from "@/store/auth.store";

const authStore = useAuthStore();
const orderStore = useOrderStore();
const confirm = useConfirm();

const currentUserIsCreator = computed(() => authStore.user?.id === orderStore.selectedOrder?.createdBy)
const selectedOrder = computed(() => orderStore.selectedOrder)
const isCreated = computed(() => orderStore.selectedOrder?.status === 'created')
const isOrdering = computed(() => orderStore.selectedOrder?.status === 'ordering')
const isOrdered = computed(() => orderStore.selectedOrder?.status === 'ordered')

// todo: implement..
const isFinished = computed(() => orderStore.selectedOrder?.status === 'finished')

// todo: implement..
const isCancelled = computed(() => orderStore.selectedOrder?.status === 'cancelled')

const previousStatus = ref<string>('');

const confirmToOrderedHTML = computed(() => `
Kunnen deelnemers zelf het bedrag bepalen?<br />
Je kunt dit niet meer wijzigen namelijk!<br /><br />

Test het nog één keer voor de zekerheid:<br />
<a href="${selectedOrder.value?.paymentLink}" class="white-space-nowrap" target="_blank">
  ${selectedOrder.value?.paymentLink} <i class="pi pi-external-link"></i>
</a>
`);

function setStatus(newStatus: string, additionalData: object = {}, confirmData: null | {
  header: string,
  message: string
} = null) {
  if (confirmData !== null) {
    confirm.require({
      group: 'headless',
      header: confirmData.header,
      message: confirmData.message,
      acceptLabel: 'Bevestig',
      rejectLabel: 'Annuleer',
      accept: () => {
        orderStore.setStatus(previousStatus.value, newStatus, additionalData)
          .then((status: string) => previousStatus.value = status)
      },
    });

    return;
  }

  orderStore.setStatus(previousStatus.value, newStatus, additionalData)
    .then((status: string) => previousStatus.value = status)
}

function extractLink(event: ClipboardEvent) {
  event.preventDefault();

  const pastedText = event.clipboardData?.getData('text') ?? '';
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  const urls = pastedText.match(urlPattern);

  if (urls && urls.length > 0) {
    orderStore.setPaymentLink(urls[0]);
  } else {
    orderStore.setPaymentLink(pastedText);
  }
}

function sendPaymentLink() {
  orderStore.sendPaymentLink()
}

onMounted(() => {
  previousStatus.value = orderStore.selectedOrder?.status ?? '';
})
</script>
