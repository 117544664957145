import { defineStore } from 'pinia';
import {ToastMessageOptions} from "primevue/toast";

interface ToastStoreState {
    toasts: ToastMessageOptions[];
    triggerCount: number;
}

export const useToastStore = defineStore('toast', {
    state: (): ToastStoreState => ({
        toasts: [],
        triggerCount: 0,
    }),
    actions: {
        showToast(options: ToastMessageOptions): void {
            this.toasts.push(options)
            this.triggerCount += 1;
        },
    }
});
