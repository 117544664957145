<template>
  <Dialog
      v-if="orderStore.selectedLine && orderStore.selectedParticipant"
      v-model:visible="orderStore.lineDialogVisible"
      modal
      :header="orderStore.selectedLine.id ? 'Regel wijzigen' : 'Regel toevoegen'"
      :style="{ width: '25rem' }"
      @hide="close"
      @show="show"
  >

    <Message
        v-if="orderStore.selectedParticipant.id != authStore.user?.id"
        severity="info"
        :closable="false">
      <p>
        Let op! Je past de bestelling van {{ orderStore.selectedParticipant?.name }} aan. Wijzig alleen in overleg.
      </p>
    </Message>

    <div class="flex flex-column gap-2 mb-4">
      <label for="description" class="font-semibold">Omschrijving</label>
      <Textarea
          v-model="form.description"
          auto-resize="auto-resize"
          id="username"
          class="flex-auto"
          autocomplete="off" />
    </div>
    <div class="flex flex-column gap-2 mb-4">
      <label for="amount" class="font-semibold">Aantal</label>
      <InputNumber
          v-model="form.amount"
          @keyup="updateAmount"
          id="amount"
          class="flex-auto"
          autocomplete="off"
          min="0"/>
    </div>
    <div class="flex flex-column gap-2 mb-4">
      <label for="price" class="font-semibold">Prijs per stuk</label>
      <InputNumber
          v-model="form.price"
          @keyup="updatePrice"
          inputId="price"
          mode="currency"
          currency="EUR"
          locale="nl-NL"
          min="0" />
    </div>
    <div class="flex flex-column gap-2 mb-4">
      <label for="amount" class="font-semibold">Totale prijs</label>
      <InputNumber
          v-model="totalPrice"
          disabled
          inputId="price"
          mode="currency"
          currency="EUR"
          locale="nl-NL" />
    </div>

    <div class="flex justify-end gap-2">
      <Button type="button" label="Cancel" severity="secondary" @click="close"></Button>
      <Button type="button" label="Save" @click="save"></Button>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import {computed, reactive, ref} from "vue";
import {OrderLine} from "@/types/api/orders/order.type";
import {useOrderStore} from "@/store/order.store";
import {useAuthStore} from "@/store/auth.store";

const form = reactive<OrderLine>({
  id: null,
  description: "",
  price: 0,
  amount: 0,
  totalPrice: 0,
});

const orderStore = useOrderStore();
const authStore = useAuthStore();

const originalOrderLine = ref<OrderLine|null>(null)
const totalPrice = computed<number>((): number => form.price * form.amount)

function show() {
  if (orderStore.selectedLine == null) {
    console.error('No selected line..');
    return;
  }

  originalOrderLine.value = orderStore.selectedLine;

  form.id = orderStore.selectedLine.id;
  form.description = orderStore.selectedLine.description;
  form.price = orderStore.selectedLine.price;
  form.amount = orderStore.selectedLine.amount;
}

function close() {
  resetState();

  orderStore.closeLineDialog()
}

function updatePrice(e: KeyboardEvent) {
  const el = e.target as HTMLInputElement;
  const newValue = el.value ? parseFloat(el.value.replace(/[^\d.-]/g, '')) / 100 : null

  if (newValue === null) {
    return;
  }

  form.price = newValue;

  updateTotalPrice()
}

function updateAmount(e: KeyboardEvent) {
  const el = e.target as HTMLInputElement;
  const newValue = el.value ? parseFloat(el.value.replace(/[^\d.-]/g, '')) : null

  if (newValue === null) {
    return
  }

  form.amount = newValue;

  updateTotalPrice()
}

function updateTotalPrice() {
  form.totalPrice = Math.round(form.price * form.amount * 100) / 100;
}

async function save() {
  const upsertedLine = await orderStore.upsertLine(
      {
        ...form // do not pass form directly as it'll cause pass-by-reference issues
      },
  )

  if (upsertedLine === null) {
    return;
  }

  orderStore.closeLineDialog()
}

function resetState() {
  // reset state
  if (originalOrderLine.value !== null) {
    form.id = originalOrderLine.value.id;
    form.description = originalOrderLine.value.description;
    form.price = originalOrderLine.value.price;
    form.amount = originalOrderLine.value.amount;
    form.totalPrice = originalOrderLine.value.totalPrice;
  }
}

</script>
