import axios, {AxiosInstance, AxiosError} from 'axios';
import {useAuthStore} from "@/store/auth.store";
import config from "@/configService";
import router from "@/router";
import {useToastStore} from "@/store/toast.store";

const axiosInstance: AxiosInstance = axios.create({
    baseURL: config.apiBaseURL,
    withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
    const metaTag = getCsrfTokenField();
    if (metaTag) {
        config.headers['X-CSRF-TOKEN'] = metaTag.getAttribute('content');
    }

    return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
      if (error.response && error.response.status === 419) {
          try {
              const newToken = await useAuthStore().refreshCSRF();

              // save new token in html field for future requests
              const metaTag = getCsrfTokenField();
              if (metaTag) {
                  metaTag.setAttribute('content', newToken);
              }

              // fetch config from original request, overwrite token w/ new token
              const requestConfig = error.config;
              requestConfig.headers['X-CSRF-TOKEN'] = newToken;

              // retry request w/ new token :pray:
              return axiosInstance(requestConfig);
          } catch (refreshError) {
              handleLogout();
          }
      }

      return Promise.reject(error);
  }
);

function getCsrfTokenField() {
    return document.querySelector('meta[name="csrf-token"]');
}

function handleApiError(e: AxiosError | unknown) {
    const toast = useToastStore();

    if (isAxiosError(e) && e.response) {
        const axiosError = e as AxiosError<{ message: string, errors: Record<string, string[]> }>;
        const errorResponse = axiosError.response?.data;

        if (axiosError.response) {
            switch (axiosError.response.status) {
                case 400:
                    toast.showToast({
                        severity: 'error',
                        summary: 'Verkeerd verzoek. Controleer je invoer.',
                        life: 3500,
                    })
                    break;

                case 401:
                    handleLogout();
                    break;

                case 404:
                    toast.showToast({
                        severity: 'error',
                        summary: 'Niet gevonden.',
                        life: 3500,
                    })
                    break;

                case 422:
                    let individualErrors = [];

                    if (errorResponse && errorResponse.errors) {
                        individualErrors = Object.values(errorResponse.errors).flat();
                    } else {
                        individualErrors = [axiosError.message];
                    }

                    toast.showToast({
                        severity: 'error',
                        summary: individualErrors.join('\n'),
                        life: 3500,
                    })
                    break;

                case 500:
                    toast.showToast({
                        severity: 'error',
                        summary: 'Interne server error.',
                        life: 3500,
                    })
                    break;

                default:
                    toast.showToast({
                        severity: 'error',
                        summary: 'Onbekende error.',
                        life: 3500,
                    })
                    break;
            }
        } else if (axiosError.request) {
            toast.showToast({
                severity: 'error',
                summary: 'Geen respons van de server. Controleer je netwerk.',
                life: 3500,
            })
        } else {
            toast.showToast({
                severity: 'error',
                summary: 'Er is een fout opgetreden bij het versturen van het verzoek.',
                life: 3500,
            })
        }
    } else {
        toast.showToast({
            severity: 'error',
            summary: 'An unknown error occurred.',
            life: 3500,
        })
    }
}

function handleLogout() {
    const toast = useToastStore();

    useAuthStore().logout(false)

    // todo: reset other stores?

    toast.showToast({
        severity: 'error',
        summary: 'Uitgelogd. Log opnieuw in.',
        life: 3500,
    })

    router.push({name: 'auth.login'});
}

const isAxiosError = (error: unknown): error is AxiosError => !!error;

export {axiosInstance as axios, AxiosError, handleApiError, isAxiosError};
